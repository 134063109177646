import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { AuthState } from 'src/app/shared/enums/auth-state.enum';
import {environment} from "../../../environments/environment";
import {datadogRum} from "@datadog/browser-rum";

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isTokenValid().then((validation) => {
      if (validation) {
        //this.authService.isAuthenticate = true;
        // check if dd logging is enabled
        if (environment.datadog.enableDatadogRum) {
          let user = datadogRum.getUser();
          if (datadogRum.getUser()['id'] === undefined) {
            return this.authService.getUserInfo().then((user) => {
              datadogRum.setUser({
                id : user.attributes['sub'],
                name : user.attributes['name'],
                email : user.attributes['email']})
              return true
            }).catch((error) => {
              console.error('error',error)
              // even if it fails, we still want to return true since validation passed
              return true
            })
          }
        }
        return true;
      } else {
        this._router.navigate(['login']).then(() => {
          this.authService.isAuthenticate = AuthState.UseAuth;
          if (environment.datadog.enableDatadogRum) {
            datadogRum.clearUser();
          }
        });
        return false;
      }
    });
  }
}
